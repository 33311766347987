// Japanese
export default{
    form: {
        startSurveyButton: "開始する",
        nextButton: "次へ",
        submitAnswerButton: "回答を送信",
        continueButton: "続ける",
        finishButton: "完了",
        headerPercentageCompletion: '完了',
        headerQuestionsCompleted: '質問',
        headerDefaultErrorMessage: 'おっと。何か問題が発生しました。',
        brandingPoweredBy: '提供元',
        brandingPromoWhySurvey: 'なぜアンケート？<b>{product}</b>があるのに！',
        brandingPromoGetStarted: '今すぐ始めましょう!',
        submissionPending: "ボイスフォームを送信中です。ブラウザのウィンドウを閉じないでください。",
        submissionCanCloseWindow: "ボイスフォームが正常に送信されました。ウィンドウを閉じても大丈夫です 👍",
        mediaAttachmentOverlayCloseButton: "閉じる",
        expiredFormHeader: "ご訪問ありがとうございます！",
        expiredFormMessage: "このアンケートは有効期限が切れているようです。作成者にご連絡ください。",
        notPublishedFormHeader: "ボイスフォームは公開されていません！",
        notPublishedFormMessage: "このアンケートはまだ公開されていないようです。作成者にご連絡ください。",
        notPublishedFormRetryButton: "再試行",
        errorFormHeader: "おっと！",
        errorFormMessage: "何か問題が発生した可能性があります。後でもう一度お試しください。",
        errorFormRetryButton: "再試行",
        emptyFormHeader: "ご訪問ありがとうございます！",
        emptyFormMessage: "おっと！このフォームは現在空で、質問や入力フィールドがありません。この問題を解決するために、作成者にご連絡ください。ご理解とご協力ありがとうございます！",

        restoreFormHeader: "進行中のフォームがあります！",
        restoreFormMessage: "前回の続きから始めますか？",
        restoreFormButton: "フォームを続ける",
        restoreFormButtonNew: "最初からやり直す",

        audioPermissionHeader: "音声で回答しますか？",
        audioPermissionMessage: "話すことで回答が早く簡単になります。",
        audioPermissionAcceptButton: "はい、そうしたいです",
        audioPermissionDenyButton: "いいえ、結構です",
        audioPermissionErrorHeader: "マイクが利用できません",
        audioPermissionErrorMessage: "ブラウザの許可を確認して、マイクへのアクセスを有効にしてください。",
        audioPermissionErrorButton: "OK",
        audioRecorderFailedUpload: "録音のアップロードに失敗しました",
        silenceDetectorError: "音声が聞こえません。マイクの設定を確認してください。",
        audioRecorderButtonRecord: "録音開始",
        audioRecorderButtonRecordMore: "さらに録音",
        voiceResponseSelectionMessage: "回答方法を選択してください...",
        voiceResponseSelectionOrDivider: "または",
        voiceResponseTextInputPlaceholder: "回答を入力",
        voiceResponseTextInputCharacterCounterMinMessage: "少なくとも{min}文字を入力してください",
        voiceResponseTextInputCharacterCounterCurrentNumberMessage: "{count}文字（最小{min}文字）",
        voiceResponseTextInputCharacterValidationMessage: "{count}文字（最小{min}文字）",

        // checkbox
        checkboxValidationTooFew: "少なくとも{min}オプションを選択する必要があります",
        checkboxValidationTooMany: "最大{max}オプションまで選択できます",
        checkboxNoneOfTheAboveOption: "上記のいずれでもない",
        checkboxInvalidAnswer: "無効な回答です。",

        // datepicker
        datePickerPlaceholder: "日付を選択",

        // dropdown
        // email
        emailLabel: "メール",
        emailInvalid: "無効なメールアドレスです。",
        // file-upload
        fileUploadPluginNameCamera: "カメラ",
        fileUploadPluginNameCameraVideo: "ビデオを録画",
        fileUploadPluginScreenCast: "画面キャスト",
        fileUploadVideoImportFilesDefault: 'ビデオを録画またはアップロード:',
        fileUploadVideoImportFilesLocalFilesDisabled: 'ビデオ録画オプションを選択:',
        fileUploadVideoImportFilesNoCamera: 'ボタンを押して画面を録画',
        fileUploadVideoImportFilesNoScreenCast: 'ボタンを押してビデオを録画',
        fileUploadVideoImportFilesNoCameraAndScreenCast: 'ビデオ録画オプションがありません',

        // matrix
        matrixValidationMessage: "回答が有効です。",
        matrixRow: "行",
        matrixColumn: "列",
        matrixRowRequired: "行{rowTitle}は必須です。",
        matrixRadioGroup: "ラジオグループ",
        matrixCheckboxGroup: "チェックボックスグループ",
        matrixGroupRequired: "行{rowTitle}の{type} {groupTitle}が必要です。",
        matrixColumnRequired: "行{rowTitle}の列{columnTitle}が必要です。",
        matrixColumnInvalid: "行{rowTitle}の列{columnTitle}が無効です。",
        matrixRequired: "必須です。",
        matrixNumericMustBeNumber: "数値である必要があります。",
        matrixNumericMustBeGreaterThenMin: "{min}より大きい必要があります。",
        matrixNumericMustBeLessThenMax: "{max}より小さい必要があります。",
        matrixNumericMustBeInteger: "整数である必要があります。",
        matrixNumericInvalidNumber: "無効な数値です。",

        // name
        nameLabel: "名前",
        nameInvalid: "名と姓を指定してください。",

        // nps
        npsNotLikely: "まったく可能性がない",
        npsExtremelyLikely: "非常に可能性が高い",

        // numeric input
        numericInputRequired: "必須です。",
        numericInputMustBeNumber: "数値である必要があります。",
        numericInputMustBeGreaterThenMin: "{min}より大きい必要があります。",
        numericInputMustBeLessThenMax: "{max}より小さい必要があります。",
        numericInputMustBeInteger: "整数である必要があります。",
        numericInputInvalidNumber: "無効な数値です。",
        numericInputPlaceholder: "数字を入力",

        // phone
        phoneInvalid: "無効な電話番号です。",
        phoneCountrySelectorLabel: '国コード',
        phoneCountrySelectorError: '国を選択',
        phoneNumberLabel: '電話番号',
        phoneExample: '例:',

        // boolean
        booleanYesLabel: "はい",
        booleanNoLabel: "いいえ",

        //questionStep
        questionStepAudioQuestionLabel: "音声質問",

        // errors
        invalidPhoneNumber: "{phone}は無効な電話番号です。",
        invalidEmail: "{email}は無効なメールアドレスです。",
        questionIsRequired: "この質問は必須です。",
        answerIsNotValid: "回答が無効です。",
        unfinishedProbingDialogError: "対話を完了してください。",
        cannotResumePartialResponse: "部分的な回答を再開できません。",
        failedToSubmitForm: "フォームの送信に失敗しました。インターネット接続を確認して、再試行してください。",

        //language picker
        searchLanguage: "言語を検索",
    }
}
